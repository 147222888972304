<template>
  <Users :organization="organization" />
</template>

<script>
import Users from "@/Users/Users/Users.vue";
export default {
  components: { Users },
  inject: ["organization"]
};
</script>

<style lang="css" scoped></style>
